import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import { GalleryNav } from '../Components/Gallery/GalleryNav'

export const Gallery = () =>  {
    return (
        <div>
            <GalleryNav/>
        </div>
    );
}

